import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import {
    getAccount,
} from './API';

const AdminMarketing = () => {
    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');

    const fetchUsers = async() => {
        try {
            const data = await getAccount();
            setDisplayName(data.account.display_name);
        }
        catch (error) {
            alert(error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token !== null && token !== '') {
            fetchUsers();
        }
        else {
            navigate('/');
        }
    }, [navigate]);

    return (
        <div>
            <h2>AdminMarketing Page</h2>
            <p>Welcome to your profile!</p>
            <button onClick={() => { localStorage.setItem('access_token', ''); navigate('*'); }}>
                Logout
            </button>
        </div>
    );
};

export default AdminMarketing;
