import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import icon mắt
import '../css/Sale.css';
import '../css/Modal.css';
import {
    getAccount,
    updateDisplayName,
    createTelesaleAccount,
    getListTelesaleWithSettingAndStatistic,
    createSaleProject,
    getListSaleProject,
    createSaleDatasheet,
    getListSaleDatasheet,
} from './API';

const Sale = () => {
    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');
    const [selectedSection, setSelectedSection] = useState('telesale-setting');
    
    const [showCreateTelesaleAccountModal, setShowCreateTelesaleAccountModal] = useState(false);
    const [newTelesaleAccountCustomLoginID, setNewTelesaleAccountCustomLoginID] = useState('');
    const [newTelesaleAccountDisplayName, setNewTelesaleAccountDisplayName] = useState('');

    const [showCreateSaleProjectModal, setShowCreateSaleProjectModal] = useState(false);
    const [newSaleProjectDisplayName, setNewSaleProjectDisplayName] = useState('');

    const [showCreateSaleDatasheetModal, setShowCreateSaleDatasheetModal] = useState(false);
    const [newSaleDatasheetDisplayName, setNewSaleDatasheetDisplayName] = useState('');

    const [showChangeDisplayNameModal, setShowChangeDisplayNameModal] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState('');

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [listTelesaleWithSettingAndStatistic, setListTelesaleWithSettingAndStatistic] = useState([]);
    const [listSaleProject, setListSaleProject] = useState([]);
    const [listSaleDatasheet, setListSaleDatasheet] = useState([]);
    const [openDatasheetFolders, setOpenDatasheetFolders] = useState(new Set());

    const [currentTelesaleSettingAccountID, setCurrentTelesaleSettingAccountID] = useState('');

    const fetchUsers = async() => {
        try {
            const data = await getAccount();
            setDisplayName(data.account.display_name);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token !== null && token !== '') {
            fetchUsers();
            loadListSaleProject();
            loadListSaleDatasheet();
            loadListTelesaleWithSettingAndStatistic();
        }
        else {
            navigate('/');
        }
    }, [navigate]);

    const loadListTelesaleWithSettingAndStatistic = async () => {
        try {
            const data = await getListTelesaleWithSettingAndStatistic();
            const isSuccess = data.success;
            if (isSuccess === true) {
                const listTelesaleWithSettingAndStatistic = data.list_telesale_with_setting_and_statistic;
                console.log(listTelesaleWithSettingAndStatistic);
                setListTelesaleWithSettingAndStatistic(listTelesaleWithSettingAndStatistic);
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const loadListSaleProject = async () => {
        try {
            const data = await getListSaleProject();
            const isSuccess = data.success;
            if (isSuccess === true) {
                setListSaleProject(data.list_sale_project);
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const loadListSaleDatasheet = async () => {
        try {
            const data = await getListSaleDatasheet();
            const isSuccess = data.success;
            if (isSuccess === true) {
                console.log(JSON.stringify(data.list_sale_datasheet));
                const buildTree = (items) => {
                    const map = {};
                    const roots = [];
              
                    items.forEach(item => {
                      map[item.id] = { ...item, children: [] };
                    });
              
                    items.forEach(item => {
                      if (item.parent_folder === 0) {
                        roots.push(map[item.id]);
                      } else {
                        map[item.parent_folder]?.children.push(map[item.id]);
                      }
                    });
              
                    return roots;
                };

                const saleDatasheetTreeData = buildTree(data.list_sale_datasheet);
                setListSaleDatasheet(saleDatasheetTreeData);
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleCreateTelesaleAccount = async () => {
        try {
            const userData = {
                custom_login_id: newTelesaleAccountCustomLoginID,
                display_name: newTelesaleAccountDisplayName,
            };
            const data = await createTelesaleAccount(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListTelesaleWithSettingAndStatistic();
                setShowCreateTelesaleAccountModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleCreateSaleProject = async () => {
        try {
            const userData = {
                display_name: newSaleProjectDisplayName,
            };
            const data = await createSaleProject(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListSaleProject();
                setShowCreateSaleProjectModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const handleCreateSaleDatasheet = async () => {
        try {
            const userData = {
                is_folder: 0,
                parent_folder: 0,
                display_name: newSaleDatasheetDisplayName,
            };
            const data = await createSaleDatasheet(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListSaleDatasheet();
                setShowCreateSaleDatasheetModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }
    
    const handleDisplayNameChange = async () => {
        try {
            const userData = {
                new_display_name: newDisplayName,
            };
            const data = await updateDisplayName(userData);

            const token = data.new_access_token;
            localStorage.setItem('access_token', token);

            setShowChangeDisplayNameModal(false);
            alert("Cập nhật tên hiển thị thành công");

            fetchUsers();
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };

    const getProjectDisplayName = (id) => {
        if (id === 0) {
            return 'Chưa chọn Dự án';
        }
        const project = listSaleProject.find(project => project.id === id);
        if (project === null) {
            return 'Không có';
        }
        return project.display_name;
    }

    const getDatasheetDisplayName = (id) => {
        if (id === 0) {
            return 'Chưa chọn Data';
        }
        const datasheet = listSaleDatasheet.find(datasheet => datasheet.id === id);
        if (datasheet === null) {
            return 'Không có';
        }
        return datasheet.display_name;
    }
    
    const handlePasswordChange = async () => {
        alert('Tính năng đang phát triển');
    };

    const onChangeTelesaleSettingProject = (account_id) => {
        setCurrentTelesaleSettingAccountID(account_id);
    }

    const onChangeTelesaleSettingDatasheet = (account_id) => {
        setCurrentTelesaleSettingAccountID(account_id);
    }

    const onChangeTelesaleSettingNetwork = (account_id) => {
        setCurrentTelesaleSettingAccountID(account_id);
    }

    const onChangeTelesaleSettingRule = (account_id) => {
        alert('Tính năng đang phát triển');
    }

    const onClickButtonSaleCallTelesale = () => {
        navigate('/telesale');
    }

    const onClickButtonCreateTelesaleAccount = () => {
        setNewTelesaleAccountCustomLoginID('');
        setNewTelesaleAccountDisplayName('');
        setShowCreateTelesaleAccountModal(true);
    }

    const onClickButtonCreateSaleProject = () => {
        setNewSaleProjectDisplayName('');
        setShowCreateSaleProjectModal(true);
    }

    const onClickButtonCreateSaleDatasheet = () => {
        setNewSaleDatasheetDisplayName('');
        setShowCreateSaleDatasheetModal(true);
    }

    const onClickButtonChangeDisplayName = () => {
        setNewDisplayName('');
        setShowChangeDisplayNameModal(true);
    };

    const onClickButtonChangePassword = () => {
        setOldPassword('');
        setNewPassword('');
        setShowChangePasswordModal(true);
    };

    const onClickButtonLogout = () => {
        const isConfirmed = window.confirm('Bạn có chắc chắn muốn đăng xuất không?');
        if (isConfirmed) {
            localStorage.setItem('access_token', '');
            navigate('*');
        }
    };

    const toggleFolder = (id) => {
        const newOpenFolders = new Set(openDatasheetFolders);
        if (newOpenFolders.has(id)) {
          newOpenFolders.delete(id);
        } else {
          newOpenFolders.add(id);
        }
        setOpenDatasheetFolders(newOpenFolders);
    };

    const renderTree = (nodes, level = 0) => {
        return nodes.map((node) => (
          <div key={node.id}>
            <div style={{ marginLeft: level * 20 }}>
              {node.is_folder ? (
                <div>
                  <strong
                    onClick={() => toggleFolder(node.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    {node.display_name || 'Untitled'} {openDatasheetFolders.has(node.id) ? '[-]' : '[+]'}
                  </strong>
                  {openDatasheetFolders.has(node.id) && node.children && node.children.length > 0 && renderTree(node.children, level + 1)}
                </div>
              ) : (
                <div>{node.display_name || 'Untitled'}</div>
              )}
            </div>
          </div>
        ));
      };

    const renderSection = () => {
        switch (selectedSection) {
            case 'telesale-setting':
                return (
                    <div className="telesale-setting">
                        <h1>Cài đặt Telesale</h1>
                        <div className="telesale-list-container">
                            <div className="telesale-list-title">
                                <div className="title-item">Tên</div>
                                <div className="title-item">Số SĐT</div>
                                <div className="title-item">Dự án</div>
                                <div className="title-item">Data</div>
                                <div className="title-item">Mạng</div>
                                <div className="title-item">Luật</div>
                                <div className="title-item">Thao tác</div>
                            </div>
                            
                            <div className="telesale-list">
                                {listTelesaleWithSettingAndStatistic.map(telesale => (
                                    <div key={telesale.id}>
                                        <div className="telesale-item">
                                            <label>{telesale.display_name}</label>
                                            <label>{telesale.setting.number_phone_number}</label>
                                            <label onClick={() => onChangeTelesaleSettingProject(telesale.account_id)}>{getProjectDisplayName(telesale.setting.project)}</label>
                                            <label onClick={() => onChangeTelesaleSettingDatasheet(telesale.account_id)}>{getDatasheetDisplayName(telesale.setting.datasheet)}</label>
                                            <label onClick={() => onChangeTelesaleSettingNetwork(telesale.account_id)}>{telesale.setting.network}</label>
                                            <label onClick={() => onChangeTelesaleSettingRule(telesale.account_id)}>Chưa gọi lần nào</label>
                                            <label>Chưa có</label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            case 'telesale-management':
                return (
                    <div>
                        <h1>Quản lý Telesale</h1>
                        <h1>Tính năng đang phát triển</h1>
                    </div>
                );
            case 'marketing-management':
                return (
                    <div>
                        <h1>Quản lý Marketing</h1>
                        <h1>Tính năng đang phát triển</h1>
                    </div>
                );
            case 'project-management':
                return (
                    <div className="project-management">
                        <h1>Quản lý Dự án</h1>
                        <div className="project-management-menubar">
                            <button className="project-management-menubar-btn" onClick={() => onClickButtonCreateSaleProject()}>Tạo Dự án mới</button>
                        </div>
                        <div className="project-list-container">
                            <div className="project-list-title">
                                <div className="title-item">Mã Dự án</div>
                                <div className="title-item">Tên Dự án</div>
                                <div className="title-item">Thao tác</div>
                            </div>
                            <div className="project-list">
                                {listSaleProject.length > 0 ? listSaleProject.map(project => (
                                    <div key={project.id}>
                                        <div className="project-item">
                                            <label>{project.id}</label>
                                            <label>{project.display_name}</label>
                                            <div>
                                                <button>Đổi tên Dự án</button>
                                                <button>Xóa dự án</button>
                                            </div>
                                        </div>
                                    </div>
                                )) : (<div className="project-item-empty">Chưa có Dự án nào</div>)}
                            </div>
                        </div>
                    </div>
                );
            case 'datasheet-management':
                return (
                    <div>
                        <h1>Quản lý Data</h1>
                        {renderTree(listSaleDatasheet)}
                    </div>
                );
            default:
                return <div><h1>404</h1></div>;
        }
    };

    return (
        <div className="layout">
            <div className="sidebar">
                <h3>Xin chào, {displayName}</h3>
                <div className='seperate-line'/>
                <button className={`sidebar-btn ${selectedSection === 'telesale-setting' ? 'active' : ''}`} onClick={() => setSelectedSection('telesale-setting')}>Cài đặt Telesale</button>
                <button className={`sidebar-btn ${selectedSection === 'telesale-management' ? 'active' : ''}`} onClick={() => setSelectedSection('telesale-management')}>Quản lý Telesale</button>
                <button className={`sidebar-btn ${selectedSection === 'marketing-management' ? 'active' : ''}`} onClick={() => setSelectedSection('marketing-management')}>Quản lý Marketing</button>
                <button className={`sidebar-btn ${selectedSection === 'project-management' ? 'active' : ''}`} onClick={() => setSelectedSection('project-management')}>Quản lý Dự án</button>
                <button className={`sidebar-btn ${selectedSection === 'datasheet-management' ? 'active' : ''}`} onClick={() => setSelectedSection('datasheet-management')}>Quản lý Data</button>
                <button className="sidebar-action-btn" onClick={onClickButtonSaleCallTelesale}>Tự gọi điện telesale</button>
                <div className='seperate-line'/>
                <button className="sidebar-btn" onClick={onClickButtonCreateTelesaleAccount}>Tạo tài khoản Telesale</button>
                <button className="sidebar-btn" onClick={onClickButtonChangeDisplayName}>Đổi tên hiển thị</button>
                <button className="sidebar-btn" onClick={onClickButtonChangePassword}>Đổi mật khẩu</button>
                <div className='seperate-line'/>
                <button className="sidebar-logout-btn" onClick={onClickButtonLogout}>Đăng xuất</button>
            </div>
            <div className="content">
                {renderSection()}
            </div>

            {/* Modal Tạo Tài Khoản Telesale */}
            {showCreateTelesaleAccountModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowCreateTelesaleAccountModal(false)}>✖</button>
                        <h2>Tạo tài khoản Telesale</h2>
                        <input
                            type="text"
                            placeholder="Tài khoản đăng nhập"
                            value={newTelesaleAccountCustomLoginID}
                            onChange={(e) => setNewTelesaleAccountCustomLoginID(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Tên hiển thị"
                            value={newTelesaleAccountDisplayName}
                            onChange={(e) => setNewTelesaleAccountDisplayName(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={() => handleCreateTelesaleAccount()}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Tạo Dự Án */}
            {showCreateSaleProjectModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowCreateSaleProjectModal(false)}>✖</button>
                        <h2>Tạo Dự án mới</h2>
                        <input
                            type="text"
                            placeholder="Tên Dự án"
                            value={newSaleProjectDisplayName}
                            onChange={(e) => setNewSaleProjectDisplayName(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={handleCreateSaleProject}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Tạo Data */}
            {showCreateSaleDatasheetModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowCreateSaleDatasheetModal(false)}>✖</button>
                        <h2>Tạo Data mới</h2>
                        <input
                            type="text"
                            placeholder="Tên Data"
                            value={newSaleDatasheetDisplayName}
                            onChange={(e) => setNewSaleDatasheetDisplayName(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={handleCreateSaleDatasheet}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
            
            {/* Modal Đổi Tên Hiển Thị */}
            {showChangeDisplayNameModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangeDisplayNameModal(false)}>✖</button>
                        <h2>Đổi Tên Hiển Thị</h2>
                        <input
                            type="text"
                            placeholder="Tên hiển thị mới"
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={handleDisplayNameChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal Đổi Mật Khẩu */}
            {showChangePasswordModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangePasswordModal(false)}>✖</button>
                        <h2>Đổi Mật Khẩu</h2>
                        <div className="password-field">
                            <input
                                type={showOldPassword ? "text" : "password"}
                                placeholder="Mật khẩu cũ"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <span className="eye-icon" onClick={() => setShowOldPassword(!showOldPassword)}>
                                {showOldPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="password-field">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Mật khẩu mới"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <span className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                {showNewPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="modal-buttons">
                            <button onClick={handlePasswordChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Sale;