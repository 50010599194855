import axios from 'axios';

const api = axios.create({
  baseURL: 'https://duongpm13.click:4000',
  headers: {
    'Content-Type': 'application/json',
  },
});

// account
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const login = async(userData) => {
    try {
      const response = await api.post('/account/login', userData);
      return response.data;
    }
    catch (error) {
      throw error;
    }
}

export const getAccount = async() => {
    try {
      const response = await api.get('/account/account');
      return response.data;
    }
    catch (error) {
      throw error;
    }
}

export const updateDisplayName = async(userData) => {
    try {
      const response = await api.post('/account/update_display_name', userData);
      return response.data;
    }
    catch (error) {
      throw error;
    }
}

// lead
export const getListSaleWithTelesale = async() => {
  try {
    const response = await api.get('/lead/get_list_sale_with_telesale');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createSaleAccount = async(userData) => {
  try {
    const response = await api.post('/lead/create_sale_account', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

// sale
export const getListTelesaleWithSettingAndStatistic = async() => {
  try {
    const response = await api.get('/sale/get_list_telesale_with_setting_and_statistic');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createTelesaleAccount = async(userData) => {
  try {
    const response = await api.post('/sale/create_telesale_account', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const getListSaleProject = async() => {
  try {
    const response = await api.get('/sale/get_list_sale_project');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const getListSaleDatasheet = async() => {
  try {
    const response = await api.get('/sale/get_list_sale_datasheet');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createSaleProject = async(userData) => {
  try {
    const response = await api.post('/sale/create_sale_project', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createSaleDatasheet = async(userData) => {
  try {
    const response = await api.post('/sale/create_sale_datasheet', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

// telesale
export const getPhoneNumber = async() => {
  try {
    const response = await api.get('/telesale/get_phone_number');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export const createPhoneCall = async(userData) => {
  try {
    const response = await api.post('/telesale/create_phone_call', userData);
    return response.data;
  }
  catch (error) {
    throw error;
  }
}


// utils
export const getListRoom = async() => {
  try {
    const response = await api.get('/utils/get_list_room');
    return response.data;
  }
  catch (error) {
    throw error;
  }
}

export default api;