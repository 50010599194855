import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import icon mắt
import '../css/Lead.css';
import '../css/Modal.css';
import { getAccount, updateDisplayName, getListSaleWithTelesale, createSaleAccount } from './API';

const Lead = () => {
    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');
    const [selectedSection, setSelectedSection] = useState('telesale-management');
    
    const [showCreateSaleAccountModal, setShowCreateSaleAccountModal] = useState(false);
    const [newSaleAccountCustomLoginID, setNewSaleAccountCustomLoginID] = useState('');
    const [newSaleAccountDisplayName, setNewSaleAccountDisplayName] = useState('');

    const [showChangeDisplayNameModal, setShowChangeDisplayNameModal] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState('');

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const [listSale, setListSale] = useState([]);
  
    const toggleTelesalesVisibility = (saleId) => {
      setListSale(prevSales => 
        prevSales.map(sale =>
          sale.id === saleId
            ? { ...sale, showTelesales: !sale.showTelesales }
            : sale
        )
      );
    };

    const fetchUsers = async() => {
        try {
            const data = await getAccount();
            setDisplayName(data.account.display_name);
        }
        catch (error) {
            alert(error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token !== null && token !== '') {
            fetchUsers();
            loadListSaleWithTelesale();
        }
        else {
            navigate('/');
        }
    }, [navigate]);

    const loadListSaleWithTelesale = async () => {
        try {
            const listSale = await getListSaleWithTelesale();
            setListSale(listSale);
        }
        catch (error) {
            alert(error);
        }
    }

    const handleCreateSaleAccount = async () => {
        try {
            const userData = {
                custom_login_id: newSaleAccountCustomLoginID,
                display_name: newSaleAccountDisplayName,
            };
            const data = await createSaleAccount(userData);

            const isSuccess = data.success;
            if (isSuccess === true) {
                await loadListSaleWithTelesale();
                setShowCreateSaleAccountModal(false);
            }
            alert(data.message);
        }
        catch (error) {
            alert(error);
        }
    }
    
    const handleDisplayNameChange = async () => {
        try {
            const userData = {
                new_display_name: newDisplayName,
            };
            const data = await updateDisplayName(userData);

            const token = data.new_access_token;
            localStorage.setItem('access_token', token);

            setShowChangeDisplayNameModal(false);
            alert("Cập nhật tên hiển thị thành công");

            fetchUsers();
        }
        catch (error) {
            alert(error);
        }
    };
    
    const handlePasswordChange = async () => {
        alert('Tính năng đang phát triển');
    };

    const onClickButtonCreateSaleAccount = () => {
        setNewSaleAccountCustomLoginID('');
        setNewSaleAccountDisplayName('');
        setShowCreateSaleAccountModal(true);
    }

    const onClickButtonChangeDisplayName = () => {
        setNewDisplayName('');
        setShowChangeDisplayNameModal(true);
    };

    const onClickButtonChangePassword = () => {
        setOldPassword('');
        setNewPassword('');
        setShowChangePasswordModal(true);
    };

    const onClickButtonLogout = () => {
        const isConfirmed = window.confirm('Bạn có chắc chắn muốn đăng xuất không?');
        if (isConfirmed) {
            localStorage.setItem('access_token', '');
            navigate('*');
        }
    };

    const renderSection = () => {
        switch (selectedSection) {
            case 'telesale-management':
                return (
                    <div className="telesale-management">
                        <h1>Quản lý Telesale</h1>
                        <div className="telesale-management-menubar">
                            <button className="telesale-management-menubar-btn">Truy xuất</button>
                        </div>
                        <div className="sale-list-container">
                            <div className="sale-list-title">
                                <div className="title-item">Tên</div>
                                <div className="title-item">Số cuộc gọi</div>
                                <div className="title-item">Xem telesale</div>
                            </div>
                            
                            <div className="sale-list">
                                {listSale.length > 0 ? listSale.map(sale => (
                                    <div key={sale.id}>
                                        <div className="sale-item">
                                            <label>{sale.display_name}</label>
                                            <label>{sale.call_number}</label>
                                            <div>
                                                <button onClick={() => toggleTelesalesVisibility(sale.id)}>
                                                    {sale.showTelesales ? '▲' : '▼'}
                                                </button>
                                            </div>
                                        </div>
                                        
                                        {sale.showTelesales && (
                                            <div className="telesales-list">
                                                {sale.listTelesale.length > 0 ? (
                                                    sale.listTelesale.map(telesale => (
                                                    <div key={telesale.id} className="telesale-item">
                                                        <label>{telesale.display_name}</label>
                                                        <label>{telesale.call_number}</label>
                                                    </div>
                                                    ))
                                                ) : (<div className="telesale-item-empty">Chưa có Telesale</div>)}
                                            </div>)}
                                    </div>
                                )) : (<div className="telesale-item-empty">Chưa có Sale</div>)}
                            </div>
                        </div>
                    </div>
                );
            case 'marketing-management':
                return (
                    <div>
                        <h1>Quản lý Marketing</h1>
                        <h1>Tính năng đang phát triển</h1>
                    </div>
                );
            default:
                return <div><h1>404</h1></div>;
        }
    };

    return (
        <div className="layout">
            <div className="sidebar">
                <h3>Xin chào, {displayName}</h3>
                <div className='seperate-line'/>
                <button className={`sidebar-btn ${selectedSection === 'telesale-management' ? 'active' : ''}`} onClick={() => setSelectedSection('telesale-management')}>Quản lý Telesale</button>
                <button className={`sidebar-btn ${selectedSection === 'marketing-management' ? 'active' : ''}`} onClick={() => setSelectedSection('marketing-management')}>Quản lý Marketing</button>
                <button className="sidebar-btn" onClick={onClickButtonCreateSaleAccount}>Tạo tài khoản Sale</button>
                <button className="sidebar-btn" onClick={onClickButtonChangeDisplayName}>Đổi tên hiển thị</button>
                <button className="sidebar-btn" onClick={onClickButtonChangePassword}>Đổi mật khẩu</button>
                <button className="sidebar-logout-btn" onClick={onClickButtonLogout}>Đăng xuất</button>
            </div>
            <div className="content">
                {renderSection()}
            </div>

            {/* Modal Tạo Tài Khoản Sale */}
            {showCreateSaleAccountModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowCreateSaleAccountModal(false)}>✖</button>
                        <h2>Tạo tài khoản Sale</h2>
                        <input
                            type="text"
                            placeholder="Tài khoản đăng nhập"
                            value={newSaleAccountCustomLoginID}
                            onChange={(e) => setNewSaleAccountCustomLoginID(e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Tên hiển thị"
                            value={newSaleAccountDisplayName}
                            onChange={(e) => setNewSaleAccountDisplayName(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={handleCreateSaleAccount}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal Đổi Tên Hiển Thị */}
            {showChangeDisplayNameModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangeDisplayNameModal(false)}>✖</button>
                        <h2>Đổi Tên Hiển Thị</h2>
                        <input
                            type="text"
                            placeholder="Tên hiển thị mới"
                            value={newDisplayName}
                            onChange={(e) => setNewDisplayName(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button onClick={handleDisplayNameChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal Đổi Mật Khẩu */}
            {showChangePasswordModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={() => setShowChangePasswordModal(false)}>✖</button>
                        <h2>Đổi Mật Khẩu</h2>
                        <div className="password-field">
                            <input
                                type={showOldPassword ? "text" : "password"}
                                placeholder="Mật khẩu cũ"
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                            <span className="eye-icon" onClick={() => setShowOldPassword(!showOldPassword)}>
                                {showOldPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="password-field">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                placeholder="Mật khẩu mới"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <span className="eye-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                                {showNewPassword ? <FiEye /> : <FiEyeOff />}
                            </span>
                        </div>
                        <div className="modal-buttons">
                            <button onClick={handlePasswordChange}>Xác nhận</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Lead;
