import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';  // Thêm import biểu tượng mắt
import '../css/Login.css';
import { login, getAccount } from './API';

const Login = () => {
    const [customLoginID, setCustomLoginID] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);  // Thêm state cho mật khẩu
    const navigate = useNavigate();

    const fetchUsers = async () => {
        try {
            const data = await getAccount();
            const position = data.account.position.toLowerCase();
            if (position === 'admin_marketing') {
                navigate('/admin_marketing');
            }
            if (position === 'admin_telesale') {
                navigate('/admin_telesale');
            }
            if (position === 'lead') {
                navigate('/lead');
            }
            if (position === 'sale') {
                navigate('/sale');
            }
            if (position === 'telesale') {
                navigate('/telesale');
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token !== null && token !== '') {
            fetchUsers();
        }
    }, []);

    const onClickButtonLogin = async (e) => {
        e.preventDefault();
        try {
            const userData = {
                'custom_login_id' : customLoginID.toLowerCase(),
                'password' : password,
            };
            const data = await login(userData);

            const is_valid_account = data.is_valid_account;
            if (is_valid_account === true) {
                const token = data.access_token;
                localStorage.setItem('access_token', token);
                fetchUsers();
            }
            else {
                alert('Tên đăng nhập không tồn tại!');
            }
        }
        catch (error) {
            alert('Đăng nhập không thành công!');
            console.log(error);
        }
    };

    return (
        <div className="login-container">
            <form onSubmit={onClickButtonLogin}>
                <h1>Đăng nhập</h1>
                <input
                    type="text"
                    placeholder="Tài khoản"
                    value={customLoginID}
                    onChange={(e) => setCustomLoginID(e.target.value)}
                    required
                />
                <div className="password-field">
                    <input
                        type={showPassword ? "text" : "password"}  // Thay đổi giữa mật khẩu và text
                        placeholder="Mật khẩu"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <span className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <FiEye /> : <FiEyeOff />}
                    </span>
                </div>
                <button type="submit">Đăng nhập</button>
            </form>
        </div>
    );
};

export default Login;
