import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/Telesale.css';
import {
    getAccount,
    getPhoneNumber,
    createPhoneCall,
} from './API';

const Telesale = () => {

    const navigate = useNavigate();
    const [displayName, setDisplayName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const fetchUsers = async() => {
        try {
            const data = await getAccount();
            setDisplayName(data.account.display_name);
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token !== null && token !== '') {
            fetchUsers();
            loadPhoneNumber();
        }
        else {
            navigate('/');
        }
    }, [navigate]);

    const loadPhoneNumber = async () => {
        try {
            const data = await getPhoneNumber();
            console.log(data);
            if (data.phone_number !== '') {
                setPhoneNumber(data.phone_number);
                toggleSection2(false);
            }
            else {
                setPhoneNumber('Hết số!');
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    }

    const onClickButtonLogout = () => {
        const isConfirmed = window.confirm('Bạn có chắc chắn muốn đăng xuất không?');
        if (isConfirmed) {
            localStorage.setItem('access_token', '');
            navigate('*');
        }
    };

    const onClickButtonCall = () => {
        window.location.href = `tel:${phoneNumber}`;
        toggleSection2(true);
    }

    const onClickButtonResult = async (result) => {
        try {
            const userData = {
                phone_number: phoneNumber,
                result: result,
                customer_note: '',
            };
            const data = await createPhoneCall(userData);
            if (data.success === true) {
                if (result === 0) {
                    alert('Tính năng ghi chú thông tin khách hàng quan tâm đang được phát triển!');
                }
                loadPhoneNumber();
            }
            else {
                alert(data.message);
            }
        }
        catch (error) {
            console.log(error);
            if (error.status === 401) {
                navigate('/');
            }
        }
    };

    const [showSection2, setShowSection2] = useState(true);

    const toggleSection2 = () => {
        setShowSection2(!showSection2);
    };

    return (
        <div className="vertical-page">
            <div className="topbar">
                <h3 className="topbar-label">Xin chào, {displayName}</h3>
                <button className="logout-button" onClick={() => onClickButtonLogout()}>Đăng xuất</button>
            </div>

            <div className="content">
                <div className="section">
                    <label>{phoneNumber}</label>
                    <button className="button button-blue" onClick={() => onClickButtonCall()}>Gọi điện</button>
                </div>

                <div className={`section section-2 ${showSection2 ? '' : 'hidden'}`}>
                    <button className="button button-green" onClick={() => onClickButtonResult(0)}>Quan tâm</button>
                    <button className="button button-red" onClick={() => onClickButtonResult(1)}>Không quan tâm</button>
                    <button className="button button-yellow" onClick={() => onClickButtonResult(2)}>Không nghe máy</button>
                    <button className="button button-purple" onClick={() => onClickButtonResult(3)}>Thuê bao</button>
                    <button className="button button-blue" onClick={() => loadPhoneNumber()}>Lấy tiếp SĐT khác</button>
                </div>
            </div>
        </div>
    );
};

export default Telesale;